import { getTemple } from "../../js/storage";
import { dataConvertOptions } from "../../js/tool";

const temple = getTemple();

export default {
  name: "文章",
  url: "/admin/volunteer-article", // rest接口表名
  query: `&filter[volunteer_article.temple_id]=${temple}&filter[volunteer_article.status]=2&sort=-id&expand=volunteer`, // rest接口query参数
  listClass: "md",
  showRowNum: true,
  requestConfig: {
    noTempleFilter: true,
  },
  // 列表渲染列
  columns: (ctx) => {
    // eslint-disable-next-line no-unused-vars
    const h = ctx.$createElement;
    return [
      { title: "文章标题", dataIndex: "title" },
      {
        title: "文章封面",
        dataIndex: "img",
        customRender: (text) => {
          return <img class="avatar" src={text} />;
        },
      },
      { title: "发布时间", dataIndex: "create_time" },
      {
        title: "志工注册小组",
        customRender: (text) => {
          return <span>{text.volunteer?.volunteerGroup?.name || "-"}</span>;
        },
      },
      {
        title: "志工姓名",
        customRender: (text) => {
          return <span>{text.volunteer?.name || "-"}</span>;
        },
      },
    ];
  },
  listInit: function() {
    this.$axios({ url: "/admin/volunteer-group", method: "GET", params: { sort: "sort,-id", pageSize: 9999 } }).then(
      (res) => {
        let groupOptions = dataConvertOptions(res.data);
        let target = this.conf.queryForm.find((item) => item.key == "group");
        if (target) {
          this.$set(target.props, "options", groupOptions);
        }
      }
    );
  },
  searchKeyType: {
    name: 2,
    group: 2,
    title: 1,
  },
  // 列表查询表单
  queryForm: [
    {
      key: "name",
      label: "",
      component: "a-input",
      props: {
        placeholder: "输入志工姓名查询",
        allowClear: true,
      },
    },
    {
      key: "group",
      label: "",
      component: "a-select",
      update: ["change"],
      props: {
        placeholder: "选择注册小组查询",
        allowClear: true,
        options: [],
        showSearch: true,
        optionFilterProp: "children",
      },
      style: {
        width: "180px",
      },
    },
    {
      key: "title",
      label: "",
      component: "a-input",
      props: {
        placeholder: "输入文章标题查询",
        allowClear: true,
        maxLength: 30,
      },
    },
  ],
  beforeEdit(detail) {
    return new Promise((resolve) => {
      detail.group = detail.volunteer?.volunteerGroup?.id;
      resolve(detail);
    });
  },
  beforeUpdate(form) {
    return new Promise((resolve) => {
      form.status = 2; // 后台添加，默认审核通过
      resolve(form);
    });
  },
  // 新增/编辑查询表单
  items: [
    {
      key: "title",
      label: "文章标题",
      component: "a-input",
      props: {
        placeholder: "请输入文章标题",
        allowClear: true,
        maxLength: 30,
      },
      rules: [{ required: true, message: "请输入文章标题", trigger: "blur" }],
    },
    {
      key: "group",
      label: "志工注册小组",
      component: "a-select",
      props: {
        placeholder: "请选择志工注册小组",
        allowClear: true,
        showSearch: true,
        optionFilterProp: "children",
      },
      rules: [{ required: true, message: "请选择志工注册小组", trigger: "change" }],
      listeners: {
        change(val) {
          // 默认首次触发 获取小组列表
          let target = this.controls.find((item) => item.key == "group");
          if (!target?.props?.options) {
            this.$axios({
              url: "/admin/volunteer-group",
              method: "GET",
              params: {
                sort: "sort,-id",
                pageSize: 9999,
              },
            }).then((res) => {
              let groupOptions = dataConvertOptions(res.data);
              this.$set(target.props, "options", groupOptions);
            });
          }
          if (val) {
            let target = this.controls.find((item) => item.key == "volunteer_id");
            if (target) {
              this.$axios({
                url: "/admin/volunteer",
                method: "GET",
                params: {
                  sort: "-id",
                  "filter[volunteer_group_id]": val,
                  pageSize: 9999,
                },
              }).then((res) => {
                let volunteerList = dataConvertOptions(res.data);
                this.$set(target.props, "options", volunteerList);
                let index = volunteerList.findIndex((item) => item.id == this.form.volunteer_id);
                if (index == -1) {
                  // 当前志工不在当前小组，则置空
                  this.$set(this.form, "volunteer_id", undefined);
                }
              });
            }
          } else {
            this.$set(this.form, "volunteer_id", undefined);
          }
        },
      },
    },
    {
      key: "volunteer_id",
      label: "志工姓名",
      component: "a-select",
      props: {
        placeholder: "请选择志工姓名",
        allowClear: true,
        optionFilterProp: "children",
      },
      rules: [{ required: true, message: "请选择志工姓名", trigger: "change" }],
    },
    {
      key: "img",
      label: "文章封面",
      component: "form-upload",
      props: {
        limit: 1,
        uploadTip: "请上传jpg、png、jpeg格式的图片，最多一张",
      },
      rules: [{ required: true, message: "请上传文章封面", trigger: "change" }],
    },
    {
      key: "content",
      label: "文章内容",
      component: "rich-editor",
      props: {
        placeholder: "请填写文章内容",
      },
      rules: [{ required: true, message: "请填写文章内容", trigger: "blur" }],
    },
  ],
  expand: "?expand=volunteer", // 详情接口expand字段
  init: {}, // 新增实体初始化字段
  // 详情渲染字段
  renderItems: (ctx) => {
    // eslint-disable-next-line no-unused-vars
    const h = ctx.$createElement;
    return [
      {
        label: "文章标题",
        key: "title",
      },
      {
        label: "志工注册小组",
        slot: (entity) => <span>{entity.volunteer?.volunteerGroup?.name || "-"}</span>,
      },
      {
        label: "志工姓名",
        slot: (entity) => <span>{entity.volunteer?.name || "-"}</span>,
      },
      {
        label: "文章封面",
        key: "img",
        img: true,
      },
      {
        label: "文章内容",
        key: "content",
        rich: true,
      },
    ];
  },
};
