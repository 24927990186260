export default {
    name: '殿堂',
    url: '/admin/mini-temple', // rest接口表名
    query: '', // rest接口query参数
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {title: '殿堂名称', dataIndex: 'name'},
            {title: '佛像名称', dataIndex: 'fo_name'},
            {title: '佛像图片', dataIndex: 'img', customRender: text => text ? <s-img src={text} /> : '--'},
            {title: '排序', dataIndex: 'sort'},
        ]
    },
    // 新增/编辑查询表单
    items: [
        {
            key: 'name',
            label: '殿堂名称',
            component: 'a-input',
            props: {
                placeholder: '请输入殿堂名称',
            },
            rules: [{ required: true, message: '请输入殿堂名称', trigger: 'blur' }]
        },
        {
            key: 'temple_info',
            label: '殿堂介绍',
            component: 'rich-editor',
        },
        {
            key: 'fo_name',
            label: '佛像名称',
            component: 'a-input',
            props: {
                placeholder: '请输入佛像名称',
            },
            rules: [{ required: true, message: '请输入佛像名称', trigger: 'blur' }]
        },
        {
            key: 'img',
            label: '佛像图片',
            component: 'form-upload',
            props: {
                limit: 1
            },
            rules: [{ required: true, message: '请上传佛像图片', trigger: 'change' }]
        },
        {
            key: 'fo_info',
            label: '佛像介绍',
            component: 'rich-editor',
        },
        {
            key: 'sort',
            label: '排序',
            component: 'a-input-number',
            props: {
                placeholder: '数字越小排位越前',
            },
            style: {
                width: '240px'
            }
        },
    ],
    // 详情接口expand字段
    expand: '',
    init: {}, // 新增实体初始化字段
    // 详情渲染字段
    renderItems: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {
                label: '殿堂名称',
                key: 'name',
            },
            {
                label: '殿堂介绍',
                key: 'temple_info',
                rich: true
            },
            {
                label: '佛像名称',
                key: 'fo_name',
            },
            {
                label: '佛像图片',
                key: 'img',
                img: true
            },
            {
                label: '佛像介绍',
                key: 'fo_info',
                rich: true
            },
            {
                label: '排序',
                key: 'sort',
            },
        ]
    }
}
