import { getKeyTitle } from "../../js/tool";

export const articleTypeList = [
    {key: 1, title: '梵呗音乐'},
    {key: 2, title: '云林禅茶'},
    {key: 3, title: '云林千秋'},
    {key: 4, title: '云林禅棋'},
    {key: 5, title: '孝亲感恩'},
    {key: 6, title: '灵隐动态'},
    {key: 7, title: '云林公益'},
    {key: 8, title: '云林展览'},
]

export const articleSourceTypeList =[
    {key: 1, title: '跳转链接'},
    {key: 2, title: '文章发布'},
]

export const getArticleTypeText = function (type) {
    const types = type ? type.split(",").map(Number) : []
    return types.map(t => getKeyTitle(articleTypeList, t)).join("、");
}

export const getArticleSourceTypeText = function (source) {
    return getKeyTitle(articleSourceTypeList, source);
}

const articleLinkInput = {
    key: 'content',
    label: '链接地址',
    component: 'a-input',
    props: {
        placeholder: '请输入链接地址',
    },
    rules: [{ required: true, message: '请输入链接地址，注：链接地址仅支持微信公众号文章地址或小程序授权过的域名地址', trigger: 'blur' }]
}

const articleContentInput = {
    key: 'content',
    label: '文章内容',
    component: 'rich-editor',
}

export default {
    name: '文章',
    url: '/admin/mini-news', // rest接口表名
    query: '&sort=-id', // rest接口query参数
    batchDeleteUrl: "/admin/mini-news/batch-delete",
    unit: '篇',
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {title: '文章标题', dataIndex: 'title'},
            {title: '类型', dataIndex: 'type', customRender: text => getArticleTypeText(text) || '--'},
            {title: '发布时间', dataIndex: 'upload_time'},
            {title: '浏览量', dataIndex: 'browse'},
        ]
    },
    // 列表查询表单
    queryForm: [
        {
            key: 'type',
            label: '类型',
            component: 'a-select',
            props: {
                placeholder: '选择类型筛选',
                options: [
                    { key: 0, title: "全部文章" },
                    ...articleTypeList
                ]
            },
            style: {
                width: "200px"
            }
        },
        {
            key: 'title',
            label: '',
            component: 'a-input',
            props: {
                placeholder: '输入文章标题查询',
            },
        },
    ],
    // 新增/编辑查询表单
    items: [
        {
            key: 'title',
            label: '文章标题',
            component: 'a-input',
            props: {
                placeholder: '请输入文章标题',
            },
            rules: [{ required: true, message: '请输入文章标题', trigger: 'blur' }]
        },
        {
            key: 'types',
            label: '文章类型',
            component: 'a-select',
            props: {
                placeholder: '请选择文章类型',
                options: [...articleTypeList],
                mode: "multiple"
            },
            style: {
                width: "100%"
            },
            rules: [{ required: true, message: '请选择文章类型', trigger: 'change' }]
        },
        {
            key: 'img',
            label: '文章封面',
            component: 'form-upload',
            props: {
                limit: 1
            },
            rules: [{ required: true, message: '请上传文章封面', trigger: 'change' }]
        },
        {
            key: 'upload_time',
            label: '发布时间',
            component: 'a-date-picker',
            props: {
                placeholder: "请选择发布时间",
                showTime: {format: "HH:mm"},
                format: "YYYY-MM-DD HH:mm",
                valueFormat: "YYYY-MM-DD HH:mm"
            },
            rules: [{ required: true, message: '请选择发布时间', trigger: 'change' }]
        },
        {
            key: 'source',
            label: '文章来源',
            component: 'a-radio-group',
            props: {
                options: articleSourceTypeList.map(s => Object({ label: s.title, value: s.key }))
            },
            listeners: {
                change(e) {
                    const val = e.target.value;
                    const contentIndex = this.controls.findIndex(c => c.key == 'content');
                    const item = val == 1 ? {...articleLinkInput} : {...articleContentInput};
                    this.controls.splice(contentIndex, 1, item);
                },
            },
            rules: [{ required: true, message: '请选择文章来源', trigger: 'change' }]
        },
        { ...articleContentInput }
    ],
    // 详情接口expand字段
    expand: '',
    init: {
        source: 2
    }, // 新增实体初始化字段
    // 详情渲染字段
    renderItems: (ctx, detail) => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement;
        const items = [
            {
                label: '文章标题',
                key: 'title',
            },
            {
                label: '文章类型',
                slot: entity => <span>{getArticleTypeText(entity.type)}</span>
            },
            {
                label: '轮播图片',
                key: 'img',
                img: true
            },
            {
                label: '发布时间',
                key: 'upload_time',
            },
        ];
        if(detail.source == 1) {
            items.push({
                label: '文章内容',
                slot: entity => <span>跳转链接：<a href={entity.content} target="_blank">{entity.content}</a></span>
            })
        } else {
            items.push({
                label: '文章内容',
                key: 'content',
                rich: true
            })
        }
        return items;
    },
    beforeEdit: function(detail) {
        return new Promise(resolve => {
            if(detail.type) {
                detail.types = detail.type.split(",").map(Number);
            }
            resolve(detail);
        })
    },
    beforeUpdate: function (form) {
        return new Promise(resolve => {
            form.type = form.types.join(",")
            resolve(form);
        })
    },
}
