import { warningOptions } from "../constant/safe";
import { getKeyTitle } from "../js/tool";

export default {
  name: "应急预案",
  url: "/admin/security-emergency-plan", // rest接口表名
  query: "&sort=-id", // rest接口query参数
  hideDelete: true,
  hideQuery: true,
  // 列表渲染列
  columns: (ctx) => {
    // eslint-disable-next-line no-unused-vars
    const h = ctx.$createElement;
    return [
      {
        title: "预警类型",
        dataIndex: "type",
        customRender: (text) => {
          return <span>{getKeyTitle(warningOptions, text, "value", "label")}</span>;
        },
      },
    ];
  },
  // 列表查询表单
  queryForm: [],
  // 新增/编辑查询表单
  items: [
    {
      key: "type",
      label: "预警类型",
      component: "a-select",
      props: {
        disabled: true,
        options: warningOptions,
      },
      rules: [{ required: true, message: "请选择预警类型", trigger: "change" }],
    },
    {
      key: "content",
      label: "烟雾预警应急预案",
      component: "rich-editor",
      props: {
        placeholder: "请填写烟雾预警应急预案内容",
      },
      rules: [{ required: true, message: "请填写烟雾预警应急预案内容", trigger: "blur" }],
    },
    {
      key: "file",
      label: "附件",
      component: "form-upload",
      props: {
        limit: -1,
        listType: "text",
        customAccept:
          ".excel,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        size: 100 * 1024 * 1,
      },
      rules: [{ required: true, message: "请上传附件", trigger: "change" }],
    },
  ],
  // 详情接口expand字段
  expand: "",
  init: {}, // 新增实体初始化字段
  // 详情渲染字段
  renderItems: (ctx) => {
    // eslint-disable-next-line no-unused-vars
    const h = ctx.$createElement;
    return [
      {
        label: "预警类型",
        slot: (entity) => <span>{getKeyTitle(warningOptions, entity.type, "value", "label")}</span>,
      },
      {
        label: "烟雾预警应急预案",
        key: "content",
        rich: true,
      },
      {
        label: "附件",
        key: "file",
        file: true,
      },
    ];
  },
};
