import { dataConvertOptions } from "../../js/tool";
import { bigLetters } from "../../../components/question-options.vue";

export default {
  name: "题目",
  url: "/admin/volunteer-question-bank", // rest接口表名
  query: "&sort=-id&expand=train1", // rest接口query参数
  listClass: "md",
  showRowNum: true,
  // 列表渲染列
  columns: (ctx) => {
    // eslint-disable-next-line no-unused-vars
    const h = ctx.$createElement;
    return [
      { title: "题目", dataIndex: "title" },
      {
        title: "课程类型",
        dataIndex: "train",
        customRender: (text) => <span>{text?.name}</span>,
      },
      { title: "分值", dataIndex: "score" },
    ];
  },
  listInit: function() {
    this.$axios({ url: "/admin/volunteer-train-type", method: "GET", params: { sort: "-id", pageSize: 9999 } }).then(
      (res) => {
        let typeOptions = dataConvertOptions(res.data);
        let target = this.conf.queryForm.find((item) => item.key == "train_id");
        if (target) {
          this.$set(target.props, "options", typeOptions);
        }
      }
    );
  },
  searchKeyType: {
    title: 1,
  },
  // 列表查询表单
  queryForm: [
    {
      key: "train_id",
      label: "",
      component: "a-select",
      update: ["change"],
      props: {
        placeholder: "选择课程类型查询",
        allowClear: true,
        options: [],
        showSearch: true,
        optionFilterProp: "children",
      },
      style: {
        width: "180px",
      },
    },
    {
      key: "title",
      label: "",
      component: "a-input",
      props: {
        placeholder: "输入题目查询",
        allowClear: true,
      },
    },
  ],
  // 新增/编辑查询表单
  items: [
    {
      key: "train_id",
      label: "课程类型",
      component: "a-select",
      props: {
        placeholder: "请选择课程类型",
        allowClear: true,
        showSearch: true,
        optionFilterProp: "children",
      },
      rules: [{ required: true, message: "请选择课程类型", trigger: "change" }],
      listeners: {
        change() {
          // 获取类型列表
          let target = this.controls.find((item) => item.key == "train_id");
          if (!target?.props?.options) {
            this.$axios({
              url: "/admin/volunteer-train-type",
              method: "GET",
              params: {
                sort: "-id",
                pageSize: 9999,
              },
            }).then((res) => {
              let typeOptions = dataConvertOptions(res.data);
              this.$set(target.props, "options", typeOptions);
            });
          }
        },
      },
    },
    {
      key: "title",
      label: "题目",
      component: "a-input",
      props: {
        placeholder: "请输入题目",
        allowClear: true,
      },
      rules: [{ required: true, message: "请输入题目", trigger: "blur" }],
    },
    {
      key: "option",
      label: "选项",
      component: "question-options",
      rules: [{ required: true, message: "请设置选项及正确答案", trigger: "change" }],
    },
    {
      key: "score",
      label: "分值",
      component: "a-input-number",
      style: {
        width: "100%",
      },
      props: {
        placeholder: "请输入分值",
        allowClear: true,
        step: 0.5,
        min: 0,
        precision: 1,
      },
      rules: [{ required: true, message: "请输入分值", trigger: "blur" }],
    },
  ],
  init: {}, // 新增实体初始化字段
  // 详情渲染字段
  renderItems: (ctx) => {
    // eslint-disable-next-line no-unused-vars
    const h = ctx.$createElement;
    return [
      {
        label: "课程类型",
        slot: (entity) => <span>{entity.train?.name}</span>,
      },
      {
        label: "题目",
        key: "title",
      },
      {
        label: "分值",
        key: "score",
      },
      {
        label: "选项",
        slot: (entity) => {
          let option = entity.option;
          if (option) {
            option = JSON.parse(option);
            const { options, answer } = option;
            return options?.map((item, index) => {
              let letterIndex = bigLetters.findIndex(letter => letter == answer);
              let isAnswer = letterIndex == index;
              return (
                <div style={{ color: isAnswer ? "#1890ff" : "" }}>
                  <span> {item.value}. </span>
                  <span> {item.content} </span>
                  {isAnswer ? <span>（答案）</span> : ""}
                </div>
              );
            });
          } else {
            return "-";
          }
        },
      },
    ];
  },
};
