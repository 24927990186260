import { clone } from "../js/tool";

export default {
  name: "堂口",
  url: "/admin/ketang", // rest接口表名
  query: "&sort=order,-id", // rest接口query参数
  // 列表渲染列
  columns: (ctx) => {
    // eslint-disable-next-line no-unused-vars
    const h = ctx.$createElement;
    return [
      { title: "堂口名称", dataIndex: "name" },
      {
        title: "关联部门",
        dataIndex: "original_visible_ids",
        customRender: (text) => {
          let res = "无";
          if (text) {
            let books = JSON.parse(text);
            if (books?.length > 0) {
              res = books.map((b, i) => {
                return (
                  <div class="book-item" key={i}>
                    {b.type == "tag" ? (
                      <div class="icon-tag book-icon"></div>
                    ) : (
                      <a-icon class="book-icon" type={b.type == "user" ? "user" : "folder"} />
                    )}
                    {b.type == "tag" ? (
                      <div class="book-name">{b.name}</div>
                    ) : (
                      <open-data class="book-name" type={b.type + "Name"} openid={b.id} />
                    )}
                  </div>
                );
              });
            }
          }
          return res;
        },
      },
      {
        title: "部门主管",
        dataIndex: "admin_user_ids",
        customRender: (text) => {
          if (text) {
            return text?.split(",").map((id) => {
              return (
                <span class="book-item">
                  <a-icon class="book-icon" type="user" />
                  <open-data type="userName" openid={id} />
                </span>
              );
            });
          } else {
            return "-";
          }
        },
      },
      { title: "排序", dataIndex: "order" },
    ];
  },
  // 列表查询表单
  queryForm: [
    {
      key: "name",
      label: "",
      component: "a-input",
      props: {
        placeholder: "输入堂口名称查询",
      },
    },
  ],
  beforeUpdate(form) {
    let res = clone(form);
    let original_visible_ids = res.original_visible_ids;
    if (original_visible_ids) {
      let visible_ids = {
        uids: [],
        dept: [],
        tag: [],
      };
      original_visible_ids?.forEach((item) => {
        if (item.type == "department") {
          visible_ids.dept.push(item.id);
        } else if (item.type == "user") {
          visible_ids.uids.push(item.id);
        } else if (item.type == "tag") {
          visible_ids.tag.push(item.id);
        }
      });
      res.visible_ids = visible_ids;
      res.original_visible_ids = JSON.stringify(original_visible_ids);
    }
    if (res.admin_user_ids) {
      res.admin_user_ids = res.admin_user_ids.join(",");
    }
    res.type = 1; // 服务协同
    return Promise.resolve(res);
  },
  dealDetail(form) {
    if (form.original_visible_ids) {
      form.original_visible_ids = JSON.parse(form.original_visible_ids);
    }
    if (form.admin_user_ids) {
      form.admin_user_ids = form.admin_user_ids.split(",");
    }
    return Promise.resolve(form);
  },
  // 新增/编辑查询表单
  items: [
    {
      key: "name",
      label: "名称",
      component: "a-input",
      props: {
        placeholder: "请输入堂口名称",
      },
      rules: [{ required: true, message: "请输入堂口名称", trigger: "blur" }],
    },
    {
      key: "original_visible_ids",
      label: "关联部门",
      component: "select-book",
      props: {
        type: "book",
        hideUser: true,
        editable: true,
        tagType: 2,
      },
      rules: [{ required: true, message: "请设置权限范围", trigger: "change" }],
    },
    {
      key: "admin_user_ids",
      label: "部门主管",
      component: "select-book",
      props: {
        multiple: true,
        type: "user",
      },
      rules: [{ required: true, message: "请设置部门主管", trigger: "change" }],
    },
    {
      key: "order",
      label: "排序",
      component: "a-input-number",
      props: {
        placeholder: "数字越小排位越前",
      },
      style: {
        width: "240px",
      },
    },
  ],
  // 详情接口expand字段
  expand: "",
  init: {}, // 新增实体初始化字段
  // 详情渲染字段
  renderItems: (ctx) => {
    // eslint-disable-next-line no-unused-vars
    const h = ctx.$createElement;
    return [
      {
        label: "堂口名称",
        key: "name",
      },
      {
        label: "关联部门",
        slot: (entity) => {
          let original_visible_ids = entity.original_visible_ids;
          let res = "无";
          if (original_visible_ids) {
            if (original_visible_ids?.length > 0) {
              res = original_visible_ids.map((b, i) => {
                return (
                  <div class="book-item" key={i}>
                    {b.type == "tag" ? (
                      <div class="icon-tag book-icon"></div>
                    ) : (
                      <a-icon class="book-icon" type={b.type == "user" ? "user" : "folder"} />
                    )}
                    {b.type == "tag" ? (
                      <div class="book-name">{b.name}</div>
                    ) : (
                      <open-data class="book-name" type={b.type + "Name"} openid={b.id} />
                    )}
                  </div>
                );
              });
            }
          }
          return res;
        },
      },
      {
        label: "部门主管",
        slot: (entity) => {
          let admin_user_ids = entity.admin_user_ids;
          if (admin_user_ids) {
            return admin_user_ids?.map((id) => {
              return (
                <span class="book-item">
                  <a-icon class="book-icon" type="user" />
                  <open-data type="userName" openid={id} />
                </span>
              );
            });
          } else {
            return "-";
          }
        },
      },
      {
        label: "排序",
        key: "order",
      },
    ];
  },
};
