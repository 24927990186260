import { grantOptions, volunStatusOptions } from "@/common/hr/volunteer";
import { getKeyTitle, clone, isArray } from "@/common/js/tool";

const statusOptions = clone(volunStatusOptions).splice(0, 3);

const limitItems = [
  {
    key: "volunteer_status",
    label: "可进行领取的志工状态（多选）",
    component: "a-select",
    props: {
      placeholder: "请选择志工状态",
      allowClear: true,
      mode: "multiple",
      options: statusOptions,
    },
  },
  {
    key: "volunteer_id",
    label: "",
    component: "upload-volunteer",
    props: {
      label: "仅以下志工可领取",
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    },
  },
];

export default {
  name: "结缘品",
  url: "/admin/volunteer-souvenir", // rest接口表名
  query: "&sort=-id", // rest接口query参数
  listClass: "md",
  showRowNum: true,
  // 列表渲染列
  columns: (ctx) => {
    // eslint-disable-next-line no-unused-vars
    const h = ctx.$createElement;
    return [
      { title: "结缘品名称", dataIndex: "name" },
      {
        title: "发放条件",
        dataIndex: "is_limit",
        customRender: (text) => <span>{getKeyTitle(grantOptions, text, "value", "label")}</span>,
      },
    ];
  },
  searchKeyType: {
    name: 1,
  },
  // 列表查询表单
  queryForm: [
    {
      key: "name",
      label: "",
      component: "a-input",
      props: {
        placeholder: "输入结缘品名称查询",
        maxLength: 30,
        allowClear: true,
      },
    },
  ],
  // 新增/编辑查询表单
  items: [
    {
      key: "name",
      label: "结缘品名称",
      component: "a-input",
      props: {
        placeholder: "请输入结缘品名称",
        maxLength: 30,
        allowClear: true,
      },
      rules: [{ required: true, message: "请输入结缘品名称", trigger: "blur" }],
    },
    {
      key: "is_limit",
      label: "发放条件",
      component: "a-radio-group",
      props: {
        placeholder: "请选择",
        options: grantOptions,
      },
      listeners: {
        change: function(e) {
          let val = e.target.value;
          let i = this.controls.findIndex((c) => c.key == "is_limit");
          if (val == 1) {
            // 不限制
            this.controls.splice(i + 1, limitItems.length);
          } else if (val == 2) {
            // 需限制
            this.controls.splice(i + 1, 0, ...limitItems);
          }
        },
      },
      rules: [{ required: true, message: "请选择发放条件", trigger: "change" }],
    },
  ],
  beforeEdit(detail) {
    if (detail.volunteer_status) {
      detail.volunteer_status = detail.volunteer_status.split(",").map(Number);
    } else {
      detail.volunteer_status = [];
    }
    return Promise.resolve(detail);
  },
  beforeUpdate(form) {
    if (form.volunteer_status) {
      form.volunteer_status = form.volunteer_status.join(",");
    }
    return Promise.resolve(form);
  },
  init: {}, // 新增实体初始化字段
  // 详情渲染字段
  renderItems: (ctx, detail) => {
    // eslint-disable-next-line no-unused-vars
    const h = ctx.$createElement;
    let list = [
      {
        label: "结缘品名称",
        key: "name",
      },
      {
        label: "发放条件",
        slot: (entity) => <span>{getKeyTitle(grantOptions, entity.is_limit, "value", "label")}</span>,
      },
    ];
    if (detail.is_limit == 2 && detail.volunteer_status) {
      // 限制
      list.push({
        label: "可进行领取的志工状态",
        slot: (entity) => {
          let volunteer_status = entity.volunteer_status;
          volunteer_status = isArray(volunteer_status) ? volunteer_status : volunteer_status.split(",");
          return volunteer_status?.map((item) => {
            let target = statusOptions.find((val) => val.value == item);
            return <a-tag>{target.label}</a-tag>;
          });
        },
      });
    }
    return list;
  },
  component: "volunteer-list",
};
