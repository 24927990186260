export default {
  name: "共修类型",
  url: "/admin/volunteer-meditation", // rest接口表名
  query: "&sort=-id", // rest接口query参数
  listClass: "md",
  showRowNum: true,
  // 列表渲染列
  columns: (ctx) => {
    // eslint-disable-next-line no-unused-vars
    const h = ctx.$createElement;
    return [
      { title: "共修类型", dataIndex: "name" },
      { title: "单位", dataIndex: "unit" },
    ];
  },
  searchKeyType: {
    name: 1,
  },
  // 列表查询表单
  queryForm: [
    {
      key: "name",
      label: "",
      component: "a-input",
      props: {
        placeholder: "输入共修类型查询",
        allowClear: true,
      },
    },
  ],
  // 新增/编辑查询表单
  items: [
    {
      key: "name",
      label: "共修类型",
      component: "a-input",
      props: {
        placeholder: "请输入共修类型",
        allowClear: true,
      },
      rules: [{ required: true, message: "请输入共修类型", trigger: "blur" }],
    },
    {
      key: "unit",
      label: "单位",
      component: "a-input",
      props: {
        placeholder: "例如共修类型为打坐，则单位应输入“分钟”",
        allowClear: true,
      },
      rules: [{ required: true, message: "请输入单位", trigger: "blur" }],
    },
  ],
  init: {}, // 新增实体初始化字段
  // 详情渲染字段
  renderItems: (ctx) => {
    // eslint-disable-next-line no-unused-vars
    const h = ctx.$createElement;
    return [
      {
        label: "共修类型",
        key: "name",
      },
      {
        label: "单位",
        key: "unit",
      },
    ];
  },
};
