import {
    assCatList,
    assEffectTypes,
    assTypes,
    assVisibleTypes,
    getAssCatName, getAssProgramStatusText,
    getAssTypeName, getAssVisibleType
} from "../../common/hr/ass";
import {clone} from "../js/tool";

const dealAssProgram = function (detail) {
    detail.items = {
        subjective: detail.config ? JSON.parse(detail.config) : [],
        objective: detail.regular_config ? JSON.parse(detail.regular_config) : []
    };
    if(detail.setting) {
        let set = JSON.parse(detail.setting);
        if(set.deptList) {
            set.deptList = set.deptList.map(item => {
                item.user_id = item.user_id ? [item.user_id] : null;
                item.director_id = item.director_id ? [item.director_id] : null;
                return item;
            });
        }
        detail.set = set;
    }
    if(detail.original_evaluate_ids) {
        const range = JSON.parse(detail.original_evaluate_ids);
        // 旧数据
        if(!Array.isArray(range)) {
            detail.range = detail.type == 1 ? range.userList : range.dept;
        } else {
            detail.range = range;
        }
    }
}

const handleStatusChange = function (val, item) {
    const url = `/admin/evaluate-program/${item.id}`;
    this.$set(item, "loading", true);
    this.$axios({
        url,
        method: "PATCH",
        data: {
            is_auto_gen: val * 1
        }
    }).then(res => this.$set(item, 'is_auto_gen', res.is_auto_gen)).finally(() => this.$set(item, "loading", false));
}

const copyProgram = function (item) {
    const type = "assProgram";
    const obj = clone(item);
    dealAssProgram(obj);
    this.$store.commit("setDetail", { type, obj });
    this.$router.push({name: "AddEntity", params: { type } });
}

export default {
    name: '评价方案',
    url: '/admin/evaluate-program', // rest接口表名
    query: '&sort=order,-id&expand=count', // rest接口query参数
    formItemRowType: "flex",
    labelCol: {flex: '120px'},
    wrapperCol: {flex: 'auto'},
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            { title: '方案名称', dataIndex: 'name' },
            { title: '评价类型', dataIndex: 'cat', customRender: getAssCatName },
            { title: '评价对象', dataIndex: 'type', customRender: getAssTypeName },
            { title: '创建时间', dataIndex: 'create_time' },
            { title: '已生成评价', dataIndex: 'count' },
            {
                title: '方案状态',
                dataIndex: 'is_auto_gen',
                customRender: (text, record) => {
                    return  <a-space>
                        <a-switch loading={record.loading} checked={text == 1} onChange={(val) => handleStatusChange.call(ctx, val, record)}/>
                        <span>{getAssProgramStatusText(text)}</span>
                    </a-space>
                }
            },
            { title: '快捷操作', customRender: record => <a class="txt-btn" onClick={() => copyProgram.call(ctx, record)}>复制模板</a> },
        ]
    },
    // 列表查询表单
    queryForm: [
        {
            key: 'name',
            label: '',
            component: 'a-input',
            props: {
                placeholder: '输入方案名称查询',
            },
        }
    ],
    // 新增/编辑查询表单
    items: [
        {
            key: 'name',
            label: '方案名称',
            component: 'a-input',
            props: {
                placeholder: '请输入模板名称',
            },
            span: 12,
            rules: [{ required: true, message: '请输入模板名称', trigger: 'blur' }]
        },
        {
            key: 'cat',
            label: '评价类型',
            component: 'a-select',
            props: {
                placeholder: '请选择评价类型',
                options: [...assCatList],
            },
            span: 12,
            listeners: {
                change(val) {
                    const con = this.controls.find(c => c.key == "effect_type");
                    if(con) {
                        const i = con.props.options.findIndex(opt => opt.value == 2);
                        if(i >= 0) {
                            this.$set(con.props.options[i], "label", val ? ["下月生效", "下季度生效", "次年生效"][val - 1] : "下次生效");
                        }
                    }
                }
            },
            rules: [{ required: true, message: '请选择评价类型', trigger: 'change' }]
        },
        {
            key: 'effect_type',
            label: '生效时间',
            component: 'a-radio-group',
            props: {
                placeholder: '请选择生效时间',
                options: assEffectTypes.map(item => Object({label: item.title, value: item.key})),
            },
            span: 12,
            rules: [{ required: true, message: '请选择生效时间', trigger: 'change' }]
        },
        {
            key: 'type',
            label: '评价对象',
            component: 'a-radio-group',
            props: {
                placeholder: '请选择评价对象',
                options: assTypes.map(item => Object({label: item.title, value: item.key})),
            },
            listeners: {
                change(e) {
                    const val = e.target.value;
                    const range = this.controls.find(c => c.key == "range");
                    if(range) {
                        this.$set(range.props, "type", val == 1 ? "book" : "dept");
                    }
                    const con = this.controls.find(c => c.key == "set");
                    if(con) {
                        this.$set(con.props, "type", val);
                    }
                }
            },
            span: 12,
            rules: [{ required: true, message: '请选择评价对象', trigger: 'change' }]
        },
        {
            key: 'range',
            label: '评价范围',
            component: 'select-book',
            props: {
                multiple: true,
                type: "book",
            },
            span: 12,
            listeners: {
                change(val) {
                    if(this.form.type == 2) {
                        const con = this.controls.find(c => c.key == "set");
                        if(con) {
                            this.$set(con.props, "dept", val);
                        }
                    }
                }
            },
            rules: [{ required: true, message: '请选择评价范围', trigger: 'change' }]
        },
        {
            key: 'is_visible',
            label: '评价结果可见性',
            component: 'a-radio-group',
            props: {
                placeholder: '请选择评价结果可见性',
                options: assVisibleTypes.map(item => Object({label: item.title, value: item.key})),
            },
            span: 12,
            rules: [{ required: true, message: '请选择评价结果可见性', trigger: 'change' }]
        },
        {
            key: 'items',
            label: '评价项',
            component: 'ass-items',
            props: {
                multiple: true
            },
            rules: [
                { required: true, message: '请设置评价项', trigger: 'change' },
                {
                    validator: (rule, value, callback) => {
                        if(value) {
                            const rules = [...value.subjective, ...value.objective]
                            let total = rules.reduce((acc, val) => acc + val.percent * 1, 0);
                            if(total !== 100) {
                                callback(new Error('所有评价项权重之和必须为100%'));
                            } else {
                                callback();
                            }
                        } else {
                            callback(new Error('请设置评价项'));
                        }
                    },
                    trigger: 'change'
                }
            ]
        },
        {
            key: 'set',
            label: '评价设置',
            component: 'ass-setting',
            props: {
                type: 1
            },
            rules: [{
                validator: (rule, value, callback) => {
                    let tip = null;
                    if(value.deptList) {
                        for(let i = 0, l = value.deptList.length; i < l; i ++) {
                            let dept = value.deptList[i];
                            if(value.multipleSelf == 2 && !dept.user_id) {
                                tip = `请选择第${i+1}个部门的指定主管`;
                            } else if(value.multipleDirector == 2 && !dept.director_id) {
                                tip = `请选择第${i+1}个部门的指定上级主管`;
                            }
                            if(tip) {
                                callback(new Error(tip));
                                break;
                            }
                        }
                    }
                    if(!tip) {
                        callback();
                    }
                },
                trigger: 'change'
            }]
        },
    ],
    // 详情页content样式
    init: { type: 1, effect_type: 1, is_visible: 1, }, // 新增实体初始化字段
    // 详情渲染字段
    renderItems: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {
                label: '方案名称',
                key: 'name'
            },
            {
                label: '评价类型',
                slot: entity => <span>{getAssCatName(entity.cat)}</span>
            },
            {
                label: '评价对象',
                slot: entity => <span>{getAssTypeName(entity.type)}</span>
            },
            {
                label: '评价范围',
                slot: entity => <select-book edit={false} value={entity.range} type={entity.type == 1 ? 'book' : 'dept'}></select-book>
            },
            {
                label: '评价结果可见性',
                slot: entity => <span>{getAssVisibleType(entity.is_visible)}</span>
            },
            {
                label: '评价项',
                slot: entity => <ass-items value={entity.items} editable={false}></ass-items>
            },
            {
                label: '评价设置',
                slot: entity => <ass-setting type={entity.type} value={entity.setting} edit={false}></ass-setting>
            },
        ]
    },
    // 编辑实体前处理函数
    dealDetail: function(detail) {
        return new Promise(resolve => {
            dealAssProgram(detail);
            resolve();
        })
    },
    beforeUpdate: function (form) {
        return new Promise(resolve => {
            if(form.items) {
                form.config = JSON.stringify(form.items.subjective);
                form.regular_config = JSON.stringify(form.items.objective);
            }
            if(form.set) {
                let set = form.set;
                if(set.deptList) {
                    set.deptList = set.deptList.map(item => {
                        let res = {...item};
                        res.user_id = item.user_id ? item.user_id[0] : null;
                        res.director_id = item.director_id ? item.director_id[0] : null;
                        return res;
                    });
                }
                form.setting = JSON.stringify(set);
            }
            if(form.range) {
                form.original_evaluate_ids = JSON.stringify(form.range);
                if(form.type == 1) {
                    let evaluate_ids = {
                        uids: [],
                        dept: [],
                        tag: []
                    };
                    form.range.forEach(item => {
                        if(item.type == "department") {
                            evaluate_ids.dept.push(item.id);
                        } else if(item.type == 'user') {
                            evaluate_ids.uids.push(item.id);
                        } else if(item.type == 'tag') {
                            evaluate_ids.tag.push(item.id);
                        }
                    });
                    form.evaluate_ids = evaluate_ids;
                } else {
                    form.evaluate_ids = form.range.join(",");
                }
            }
            resolve(form);
        })
    },
    component: 'HRProgramOther'
}
