import {getDoubleText, getKeyTitle} from "../js/tool";
import { staffTypes, getStaffTypeName } from "../hr/book";

export const holidayTypes = [
    {key: 1, title: '普通假期'},
    {key: 2, title: '僧职告假'},
]

const dateOptions = [];
for(let i = 0; i < 12; i++) {
    const num = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][i]
    const days = [];
    for(let j = 0; j < num; j ++) {
        days.push({
            value: j + 1,
            label: `${j + 1}日`,
        })
    }
    dateOptions.push({
        value: i + 1,
        label: `${i + 1}月`,
        children: days,
    })
}

const dateItem = {
    key: 'date',
    label: '自动重置日期',
    component: 'a-cascader',
    props: {
        placeholder: '请选择自动重置日期',
        options: dateOptions
    },
    rules: [{ required: true, message: '请选择自动重置日期', trigger: 'change' }]
}

const countItem = [
    {
        key: 'count',
        label: '假期额度',
        component: 'a-input-number',
        props: {
            placeholder: '请输入假期额度',
        },
        style: {
            width: '100%'
        },
        rules: [{ required: true, message: '请输入假期额度', trigger: 'change' }]
    },
    {
        key: 'visible',
        label: '额度可见',
        component: 'title-checkbox',
        props: {
            title: "申请流程中的所有人是否均可查看该申请人的假期余额"
        }
    },
    {
        key: 'auto',
        label: '自动重置',
        component: 'a-switch',
        listeners: {
            change: function (val) {
                const index = this.controls.findIndex(c => c.key == 'date');
                if(val) {
                    if(index === -1) {
                        this.controls.push({...dateItem})
                    }
                } else {
                    if(index >= 0) {
                        this.controls.splice(index, 1);
                    }
                }
            }
        },
        rules: [{ required: true, message: '请选择是否自动重置假期额度', trigger: 'change' }]
    },
]


export const getHolidayTypeName = function (type) {
    return getKeyTitle(holidayTypes, type);
}

export default {
    name: '请假类型',
    url: '/admin/holiday', // rest接口表名
    query: '', // rest接口query参数
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {title: '类型名称', dataIndex: 'name'},
            {title: '可见范围', dataIndex: 'type', customRender: text => <span>{getStaffTypeName(text)}</span>},
            {title: '假期类型', dataIndex: 'h_type', customRender: text => <span>{getHolidayTypeName(text)}</span>},
            {title: '假期额度', customRender: text => <span>{ text.is_limit == 1 ? `${text.count}天` : '不限额' }</span>},
            {title: '是否计入缺卡', dataIndex: 'is_leave_count', customRender: text => <span>{text == 1 ? '是' : '否'}</span>},
        ]
    },
    // 列表查询表单
    queryForm: [
        {
            key: 'name',
            label: '',
            component: 'a-input',
            props: {
                placeholder: '输入名称查询',
            },
        },
    ],
    // 新增/编辑查询表单
    items: [
        {
            key: 'name',
            label: '类型名称',
            component: 'a-input',
            props: {
                placeholder: '请输入类型名称',
            },
            rules: [{ required: true, message: '请输入类型名称', trigger: 'blur' }]
        },
        {
            key: 'type',
            label: '可见范围',
            component: 'a-select',
            props: {
                placeholder: '请选择',
                options: staffTypes
            },
            rules: [{ required: true, message: '请选择可见范围', trigger: 'change' }]
        },
        {
            key: 'h_type',
            label: '假期类型',
            component: 'a-select',
            props: {
                placeholder: '请选择假期类型',
                options: holidayTypes
            },
            listeners: {
                change(val) {
                    const index = this.controls.findIndex(c => c.key == 'is_limit');
                    if(val == 1) {
                        if(index === -1) {
                            const list = [
                                {
                                    key: 'is_limit',
                                    label: '是否限额',
                                    component: 'a-radio-group',
                                    props: {
                                        placeholder: '请选择是否限额',
                                        options: [
                                            { label: '是', value: 1 },
                                            { label: '否', value: 0 },
                                        ]
                                    },
                                    listeners: {
                                        change: function (e) {
                                            const val = e.target.value;
                                            const index = this.controls.findIndex(c => c.key == 'count');
                                            if(val == 1) {
                                                // 限额没有输入额度默认5天
                                                const count = this.entity.count || 5
                                                if(this.form.count <= 0) {
                                                    this.$set(this.form, 'count', count);
                                                }
                                                if(index === -1) {
                                                    const list = [...countItem];
                                                    if(this.form.auto) {
                                                        list.push({...dateItem});
                                                    }
                                                    this.controls.push(...list)
                                                }
                                            } else {
                                                this.$set(this.form, 'count', -1);
                                                if(index >= 0) {
                                                    this.controls = this.controls.slice(0, index);
                                                }
                                            }
                                        }
                                    },
                                    rules: [{ required: true, message: '请选择是否限额', trigger: 'change' }]
                                }
                            ];
                            if(this.form.is_limit == 1) {
                                list.push(...countItem);
                                if(this.form.auto) {
                                    list.push({...dateItem});
                                }
                            }
                            this.controls.push(...list);
                            this.setForm();
                        }
                    } else {
                        if(index >= 0) {
                            this.controls = this.controls.slice(0, index);
                        }
                    }
                }
            },
            rules: [{ required: true, message: '请选择假期类型', trigger: 'change' }]
        },
        {
            key: 'is_leave_count',
            label: '是否计入缺卡',
            component: 'a-radio-group',
            props: {
                placeholder: '请选择是否计入缺卡',
                options: [
                    { label: '是', value: 1 },
                    { label: '否', value: 0 },
                ]
            },
            rules: [{ required: true, message: '请选择是否计入缺卡', trigger: 'change' }]
        },
    ],
    // 详情接口expand字段
    expand: '',
    init: {
        is_leave_count: 0,
        is_limit: 0
    }, // 新增实体初始化字段
    // 详情渲染字段
    renderItems: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {
                label: '类型名称',
                key: 'name',
            },
            {
                label: '可见范围',
                slot: entity => <span>{getStaffTypeName(entity.type)}</span>
            },
            {
                label: '假期类型',
                slot: entity => <span>{getHolidayTypeName(entity.h_type)}</span>
            },
            {
                label: '是否计入缺卡',
                slot: entity => <span>{entity.is_leave_count == 1 ? '是' : '否'}</span>
            },
        ]
    },
    beforeEdit: function(detail) {
        return new Promise(resolve => {
            detail.auto = detail.is_auto == 1;
            detail.visible = detail.is_visible == 1;
            // 手动触发互动类型监听的change事件让表单展示;
            // let item = this.detailForm.controls.find(c => c.key == 'is_limit');
            // if(item) {
            //     item.listeners.change.call(this.detailForm, {target: {value: detail.is_limit}});
            // }
            resolve(detail);
        })
    },
    // 编辑实体前处理函数
    dealDetail: function(detail) {
        return new Promise(resolve => {
            if(detail.limit_config) {
                try {
                    const config = JSON.parse(detail.limit_config);
                    detail.is_auto = config.is_auto;
                    if(detail.is_auto == 1) {
                        detail.date = config.date.split("-").map(Number);
                    }
                } catch (e) {
                    console.error(e)
                }
            }

            resolve();
        })
    },
    beforeUpdate: function (form) {
        return new Promise(resolve => {
            if(form.is_limit == 1) {
                const config = {
                    is_auto: form.auto ? 1 : 0
                }
                if(form.auto) {
                    config.date = form.date.map(getDoubleText).join("-");
                }
                form.limit_config = JSON.stringify(config);
            } else {
                form.limit_config = null;
            }
            form.is_visible = form.visible * 1;
            delete form.is_auto;
            delete form.date;
            resolve(form);
        })
    },
    component: 'HolidayOther'
}
