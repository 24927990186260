/**
 * 佛事法师堂口管理表
 */

const handleSelectChange = function (val, item) {
    const url = `/admin/fs-tangkou/${item.id}`;
    this.$set(item, "loading", true);
    this.$axios({
        url,
        method: "PATCH",
        data: {
            is_select: val ? 1 : 0
        }
    }).then(res => this.$set(item, 'is_select', res.is_select)).finally(() => this.$set(item, "loading", false));
}

export default {
    name: '堂口',
    listTitle: '堂口管理',
    url: '/admin/fs-tangkou', // rest接口表名
    query: '', // rest接口query参数
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {title: '堂口名称', dataIndex: 'name'},
            {
                title: '普佛默认选择',
                dataIndex: 'is_select',
                customRender: (text, record) => {
                    return  <a-space>
                        <a-switch loading={record.loading} checked={text == 1} onChange={(val) => handleSelectChange.call(ctx, val, record)}/>
                        <span>{text == 1 ? '是' : '否'}</span>
                    </a-space>
                }
            },
        ]
    },
    // 列表查询表单
    queryForm: [
        {
            key: 'name',
            label: '',
            component: 'a-input',
            props: {
                placeholder: '输入堂口名称查询',
            },
        },
    ],
    // 新增/编辑查询表单
    items: [
        {
            key: 'name',
            label: '堂口名称',
            component: 'a-input',
            props: {
                placeholder: '请输入堂口名称',
            },
            rules: [{ required: true, message: '请输入堂口名称', trigger: 'blur' }]
        },
        {
            key: 'select',
            label: '普佛默认选择',
            component: 'a-switch',
        },
    ],
    // 详情接口expand字段
    expand: '',
    init: {}, // 新增实体初始化字段
    // 详情渲染字段
    renderItems: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {
                label: '堂口名称',
                key: 'name',
            },
            {
                label: "普佛默认选择",
                slot: entity => <span>{entity.is_select == 1 ? "是" : "否"}</span>
            }
        ]
    },
    // 编辑实体前处理函数
    beforeEdit: function(detail) {
        return new Promise(resolve => {
            detail.select = detail.is_select == 1;
            resolve();
        })
    },
    beforeUpdate: function (form) {
        return new Promise(resolve => {
            form.is_select = form.select ? 1 : 0;
            resolve(form);
        })
    },
    component: 'BuddhaTkOther'
}
