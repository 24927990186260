export default {
    name: '殿堂',
    url: '/admin/palace', // rest接口表名
    query: '', // rest接口query参数
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {title: '殿堂名称', dataIndex: 'name'},
            {title: '排序', dataIndex: 'order'},
        ]
    },
    // 列表查询表单
    queryForm: [
        {
            key: 'name',
            label: '',
            component: 'a-input',
            props: {
                placeholder: '输入殿堂名称查询',
            },
        },
    ],
    // 新增/编辑查询表单
    items: [
        {
            key: 'name',
            label: '名称',
            component: 'a-input',
            props: {
                placeholder: '请输入殿堂名称',
            },
            rules: [{ required: true, message: '请输入殿堂名称', trigger: 'blur' }]
        },
        {
            key: 'order',
            label: '排序',
            component: 'a-input-number',
            props: {
                placeholder: '数字越小排位越前',
            },
            style: {
                width: '240px'
            }
        },
    ],
    // 详情接口expand字段
    expand: '',
    init: {}, // 新增实体初始化字段
    // 详情渲染字段
    renderItems: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {
                label: '殿堂名称',
                key: 'name',
            },
            {
                label: '排序',
                key: 'order',
            },
        ]
    }
}
