export default {
    name: '车辆',
    url: '/admin/car', // rest接口表名
    query: '', // rest接口query参数
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {title: '车牌号', dataIndex: 'num'},
        ]
    },
    // 列表查询表单
    queryForm: [
        {
            key: 'num',
            label: '',
            component: 'a-input',
            props: {
                placeholder: '输入车牌号查询',
            },
        },
    ],
    // 新增/编辑查询表单
    items: [
        {
            key: 'num',
            label: '车牌号',
            component: 'a-input',
            props: {
                placeholder: '请输入车牌号',
            },
            rules: [{ required: true, message: '请输入车牌号', trigger: 'blur' }]
        },
    ],
    // 详情接口expand字段
    expand: '',
    init: {}, // 新增实体初始化字段
    // 详情渲染字段
    renderItems: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {
                label: '车牌号',
                key: 'num',
            },
        ]
    }
}
