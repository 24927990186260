import {getKeyTitle, isEmpty} from "../../js/tool";

export const contractStatusList = [
    { key: 0, title: "未开始" },
    { key: 1, title: "有效" },
    { key: 2, title: "结束" },
]

export const getContractStatusText = function(status) {
    return getKeyTitle(contractStatusList, status);
}

export default {
    name: '合同',
    url: '/admin/record-contract', // rest接口表名
    query: '', // rest接口query参数
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {title: '合同编号', dataIndex: 'num'},
            {title: '供应商', dataIndex: 'supplier'},
            {title: '合同名称', dataIndex: 'name'},
            {title: '开始时间', dataIndex: 'start_time'},
            {title: '结束时间', dataIndex: 'end_time'},
            {title: '状态', dataIndex: 'status', customRender: getContractStatusText},
        ]
    },
    // 列表查询表单
    queryForm: [
        {
            key: 'status',
            label: '',
            component: 'a-select',
            props: {
                placeholder: '选择状态筛选',
                options: contractStatusList.slice(1),
                allowClear: true
            },
            style: {
                width: "180px"
            }
        },
        {
            key: 'keyword',
            label: '',
            component: 'a-input',
            props: {
                placeholder: '输入供应商、合同名称搜索',
            },
            style: {
                width: "200px"
            }
        },
    ],
    customUrl(form) {
        let res = "";
        if(form.keyword) {
            res += `&filter[or][0][supplier][like]=${form.keyword}&filter[or][1][name][like]=${form.keyword}`
        }
        if(!isEmpty(form.status)) {
            res += `&filter[status]=${form.status}`;
        }
        return res;
    },
    buttons: [
        {
            name: "设置",
            component: "a-button",
            value: null,
            style: {
                "margin-left": "8px",
            },
            listeners: {
                click() {
                    this.$router.push({ name: "ContractSetting" });
                },
            },
        },
    ],
    // 新增/编辑查询表单
    items: [
        {
            key: 'num',
            label: '合同编号',
            component: 'a-input',
            props: {
                placeholder: '请输入合同编号',
            },
            rules: [{ required: true, message: '请输入合同编号', trigger: 'blur' }]
        },
        {
            key: 'supplier',
            label: '供应商',
            component: 'a-input',
            props: {
                placeholder: '请输入供应商',
            },
            rules: [{ required: true, message: '请输入供应商', trigger: 'blur' }]
        },
        {
            key: 'name',
            label: '合同名称',
            component: 'a-input',
            props: {
                placeholder: '请输入合同名称',
            },
            rules: [{ required: true, message: '请输入合同名称', trigger: 'blur' }]
        },
        {
            key: 'start_time',
            label: '开始时间',
            component: 'a-date-picker',
            props: {
                placeholder: "请选择开始时间",
                format: "YYYY-MM-DD",
                valueFormat: "YYYY-MM-DD"
            },
            rules: [{ required: true, message: '请选择开始时间', trigger: 'change' }]
        },
        {
            key: 'end_time',
            label: '结束时间',
            component: 'a-date-picker',
            props: {
                placeholder: "请选择结束时间",
                format: "YYYY-MM-DD",
                valueFormat: "YYYY-MM-DD"
            },
            rules: [{ required: true, message: '请选择结束时间', trigger: 'change' }]
        },
        {
            key: 'files',
            label: '上传文件',
            component: 'file-upload',
            props: {
                limit: 9,
            },
        }
    ],
    // 详情接口expand字段
    expand: '',
    init: {}, // 新增实体初始化字段
    dealDetail: function(detail) {
        return new Promise(resolve => {
            detail.files = detail.file ? JSON.parse(detail.file) : []
            resolve();
        })
    },
    beforeEdit: function(detail) {
        return new Promise(resolve => {
            detail.file = detail.files && detail.files.length > 0 ? JSON.stringify(detail.files) : null;
            resolve(detail);
        })
    },
    // 详情渲染字段
    renderItems: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {
                label: '合同编号',
                key: 'num',
            },
            {
                label: '供应商',
                key: 'supplier',
            },
            {
                label: '合同名称',
                key: 'name',
            },
            {
                label: '开始时间',
                key: 'start_time',
            },
            {
                label: '结束时间',
                key: 'end_time',
            },
            {
                label: '合同文件',
                key: 'files',
                file: true
            },
        ]
    }
}
