export const getRoomConfig = function (name) {
    return {
        name,
        url: '/meeting-room', // rest接口表名
        query: '&sort=order,-id&filter[type]=1', // rest接口query参数
        // 列表渲染列
        columns: ctx => {
            // eslint-disable-next-line no-unused-vars
            const h = ctx.$createElement
            return [
                {title: `${name}名称`, dataIndex: 'name'},
                {title: '容纳人数', dataIndex: 'people_count'},
                {title: `${name}位置`, dataIndex: 'location'},
                {
                    title: '可预约时间段',
                    dataIndex: 'begin_time',
                    customRender: (text, record) => {
                        return <span>{record.begin_time}-{record.end_time}</span>
                    }
                },
            ]
        },
        // 列表查询表单
        queryForm: [
            {
                key: 'name',
                label: '',
                component: 'a-input',
                props: {
                    placeholder: `输入${name}名称查询`,
                },
            }
        ],
        // 新增/编辑查询表单
        items: [
            {
                key: 'name',
                label: `${name}名称`,
                component: 'a-input',
                props: {
                    placeholder: `请输入${name}名称`,
                },
                rules: [{ required: true, message: `请输入${name}名称`, trigger: 'blur' }]
            },
            {
                key: 'people_count',
                label: '容纳人数',
                component: 'a-input-number',
                props: {
                    placeholder: '请输入容纳人数',
                },
                style: {
                    width: '240px'
                },
                rules: [{ required: true, message: '请输入容纳人数', trigger: 'blur' }]
            },
            {
                key: 'location',
                label: `${name}位置`,
                component: 'a-input',
                props: {
                    placeholder: `请输入${name}位置`,
                },
                rules: [{ required: true, message: `请输入${name}位置`, trigger: 'blur' }]
            },
            {
                key: 'time',
                label: '可预约时间段',
                component: 'time-range',
                listeners: {
                    change(val) {
                        if(val) {
                            this.$set(this.form, 'begin_time', val[0]);
                            this.$set(this.form, 'end_time', val[1]);
                        } else {
                            this.$set(this.form, 'begin_time', null);
                            this.$set(this.form, 'end_time', null);
                        }
                    }
                },
                rules: [{ required: true, message: '请设置可预约时间段', trigger: 'change' }]
            },
            {
                key: 'scope',
                label: '使用范围',
                component: 'select-book',
                props: {
                    multiple: true,
                    type: "book"
                },
                rules: [{ required: true, message: '请设置使用范围', trigger: 'change' }]
            },
        ],
        init: {type: 1}, // 新增实体初始化字段
        // 详情渲染字段
        renderItems: ctx => {
            // eslint-disable-next-line no-unused-vars
            const h = ctx.$createElement
            return [
                {
                    label: `${name}名称`,
                    key: 'name'
                },
                {
                    label: '容纳人数',
                    key: 'people_count'
                },
                {
                    label: `${name}位置`,
                    key: 'location'
                },
                {
                    label: '可预约时间段',
                    slot: entity => <span>{entity.begin_time}-{entity.end_time}</span>
                },
            ]
        },
        // 编辑实体前处理函数
        beforeEdit: function(detail) {
            return new Promise(resolve => {
                if(detail.begin_time && detail.end_time) {
                    detail.time = [detail.begin_time, detail.end_time];
                }
                if(detail.original_visible_ids) {
                    detail.scope = JSON.parse(detail.original_visible_ids);
                }
                resolve();
            })
        },
        beforeUpdate: function (form) {
            return new Promise(resolve => {
                let visible_ids = {
                    uids: [],
                    dept: [],
                    tag: []
                };
                form.scope.forEach(item => {
                    if(item.type == "department") {
                        visible_ids.dept.push(item.id);
                    } else if(item.type == 'user') {
                        visible_ids.uids.push(item.id);
                    } else if(item.type == 'tag') {
                        visible_ids.tag.push(item.id);
                    }
                });
                form.original_visible_ids = JSON.stringify(form.scope);
                form.visible_ids = visible_ids;
                resolve(form);
            })
        }
    }
}
export default getRoomConfig('会议室')
