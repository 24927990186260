import {getKeyTitle} from "../../js/tool";

export const bannerTypeList = [
    {key: 1, title: '图片'},
    {key: 2, title: '视频'},
]

export const getBannerTypeText = function (type) {
    return getKeyTitle(bannerTypeList, type);
}

const videoFormItem = {
    key: 'url',
    label: '视频上传',
    component: 'form-upload',
    props: {
        limit: 1,
        mediaType: "video"
    },
    rules: [{ required: true, message: '请上传轮播视频', trigger: 'change' }]
}

const bannerLinkInput = {
    key: 'link',
    label: '链接地址',
    component: 'a-input',
    props: {
        placeholder: '请输入链接地址',
    },
    rules: [{ required: true, message: '请输入链接地址，注：链接地址仅支持微信公众号文章地址或小程序授权过的域名地址', trigger: 'blur' }]
}

export default {
    name: '横幅',
    url: '/admin/mini-banner', // rest接口表名
    query: '&sort=-id,order', // rest接口query参数
    showRowNum: true,
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {title: '轮播标题', dataIndex: 'name'},
            {title: '轮播类型', dataIndex: 'type', customRender: text => getBannerTypeText(text) || '--'},
            {title: '轮播图/轮播封面', dataIndex: 'img', customRender: text => text ? <s-img src={text} /> : '--'},
            {title: '排序', dataIndex: 'order'},
        ]
    },
    // 列表查询表单
    queryForm: [
        {
            key: 'name',
            label: '',
            component: 'a-input',
            props: {
                placeholder: '输入标题查询',
            },
        },
    ],
    // 新增/编辑查询表单
    items: [
        {
            key: 'name',
            label: '轮播标题',
            component: 'a-input',
            props: {
                placeholder: '请输入轮播标题',
            },
            rules: [{ required: true, message: '请输入轮播标题', trigger: 'blur' }]
        },
        {
            key: 'type',
            label: '轮播类型',
            component: 'a-radio-group',
            props: {
                options: [
                    { label: '图片', value: 1 },
                    { label: '视频', value: 2 },
                ]
            },
            listeners: {
                change(e) {
                    const val = e.target.value;
                    const imgIndex = this.controls.findIndex(c => c.key == 'img');
                    const videoIndex = this.controls.findIndex(c => c.key == 'url');
                    const imgItem = this.controls[imgIndex];
                    if(val == 1) {
                        if(videoIndex >= 0) {
                            this.controls.splice(videoIndex, 1)
                        }
                        this.$set(imgItem, 'label', "轮播图片");
                        this.$set(imgItem.rules[0], 'message', "请上传轮播图片");
                    } else if(val == 2) {
                        if(videoIndex == -1) {
                            this.controls.splice(imgIndex + 1, 0, {...videoFormItem});
                        }
                        this.$set(imgItem, 'label', "视频封面");
                        this.$set(imgItem.rules[0], 'message', "请上传视频封面");
                    }
                }
            },
            rules: [{ required: true, message: '请选择轮播类型', trigger: 'change' }]
        },
        {
            key: 'img',
            label: '轮播图片',
            component: 'form-upload',
            props: {
                limit: 1
            },
            rules: [{ required: true, message: '请上传轮播图片', trigger: 'change' }]
        },
        {
            key: 'is_jump',
            label: '点击跳转',
            component: 'a-radio-group',
            props: {
                options: [
                    { label: '跳转链接', value: 1 },
                    { label: '不跳转', value: 2 },
                ]
            },
            listeners: {
                change(e) {
                    const val = e.target.value;
                    const linkIndex = this.controls.findIndex(c => c.key == 'link');
                    if(val == 1) {
                        if(linkIndex == -1) {
                            const i = this.controls.findIndex(c => c.key == 'is_jump');
                            this.controls.splice(i + 1, 0, {...bannerLinkInput});
                        }
                    } else if(val == 2 && linkIndex >= 0) {
                        this.controls.splice(linkIndex, 1);
                    }
                },
            },
            rules: [{ required: true, message: '请选择点击跳转', trigger: 'change' }]
        },
        {
            key: 'order',
            label: '排序',
            component: 'a-input-number',
            props: {
                placeholder: '数字越小排位越前',
            },
            style: {
                width: '240px'
            }
        },
    ],
    // 详情接口expand字段
    expand: '',
    init: {
        type: 1,
        is_jump: 2
    }, // 新增实体初始化字段
    // 详情渲染字段
    renderItems: (ctx, detail) => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement;
        const items = [
            {
                label: '轮播标题',
                key: 'name',
            },
            {
                label: '轮播类型',
                slot: entity => <span>{getBannerTypeText(entity.type)}</span>
            }
        ];
        if(detail.type == 1) {
            items.push({
                label: '轮播图片',
                key: 'img',
                img: true
            });
        } else {
            items.push({
                label: '轮播视频',
                key: 'video',
                media: true
            });
        }
        items.push({
            label: '跳转链接',
            slot: entity => entity.is_jump == 1 ? <a href={entity.link} target="_blank">{entity.link}</a> : <span>不跳转</span>
        })
        return items;
    },
    // 编辑实体前处理函数
    dealDetail: function(detail) {
        return new Promise(resolve => {
            if(detail.type == 2) {
                detail.video = [
                    {
                        type: 'video',
                        poster: detail.img,
                        url: detail.url
                    }
                ]
            }
            resolve();
        })
    },
}
