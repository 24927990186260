import axios from "../../common/js/req";
import {getUserWxId} from "../js/storage";

export default {
    name: '公告',
    url: '/admin/notice', // rest接口表名
    query: () => `&sort=-id&expand=readCount,readObj,confirmCount,confirmObj&filter[is_del]=0&filter[visible_ids][fis]=${getUserWxId()}`, // rest接口query参数
    hideEdit: true,
    hideDelete: true,
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {title: '时间', dataIndex: 'create_time'},
            {title: '标题', dataIndex: 'title'},
            {title: '作者', dataIndex: 'author'},
            {
                title: '确认/阅读情况',
                customRender: item => {
                    const totalNum = item.visible_ids.split(",").length;
                    return item.need_confirm == 1 ? `确认人数 ${item.confirmCount}/${totalNum}` : `阅读人数 ${item.readCount}/${totalNum}`
                }
            },
        ]
    },
    // 列表查询表单
    queryForm: [
        {
            key: 'title',
            label: '',
            component: 'a-input',
            props: {
                placeholder: '输入公告标题查询',
            },
        },
    ],
    // 新增/编辑查询表单
    items: [
        {
            key: 'range',
            label: '发布范围',
            component: 'select-book',
            props: {
                multiple: true,
                type: "book"
            },
            rules: [{ required: true, message: '请选择发布范围', trigger: 'change' }]
        },
        {
            key: 'title',
            label: '标题',
            component: 'a-input',
            props: {
                placeholder: '请输入标题',
            },
            rules: [{ required: true, message: '请输入标题', trigger: 'blur' }]
        },
        {
            key: 'author',
            label: '作者',
            component: 'a-input',
            props: {
                placeholder: '请输入作者',
            },
            rules: [{ required: true, message: '请输入作者', trigger: 'blur' }]
        },
        {
            key: 'content',
            label: '公告内容',
            component: 'a-textarea',
            props: {
                placeholder: '请输入公告内容',
                rows: 4
            },
            rules: [{ required: true, message: '请输入公告内容', trigger: 'blur' }]
        },
        {
            key: 'need_confirm',
            label: '需要确认',
            component: 'a-radio-group',
            props: {
                options: [
                    { label: '是', value: 1 },
                    { label: '否', value: 0 },
                ]
            },
            rules: [{ required: true, message: '请选择是否需要确认', trigger: 'blur' }]
        },
    ],
    // 详情接口expand字段
    expand: '?expand=readCount,readObj,confirmCount,confirmObj',
    init: { need_confirm: 0 }, // 新增实体初始化字段
    // 详情渲染字段
    renderItems: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {
                label: '时间',
                key: 'create_time',
            },
            {
                label: '标题',
                key: 'title',
            },
            {
                label: '作者',
                key: 'author',
            },
            {
                label: '内容',
                key: 'content',
                textarea: true
            },
        ]
    },
    customDelete: function(item, index, fn) {
        this.$confirm({
            title: '提示',
            content: "确定删除此公告吗？",
            onOk: () => {
                axios({
                    url: `/admin/notice/${item.id}`,
                    method: "PATCH",
                    data: {
                        is_del: 1
                    }
                }).then(fn);
            }
        });
    },
    beforeUpdate: function (form) {
        return new Promise(resolve => {
            const visible_ids = {
                uids: [],
                dept: [],
                tag: []
            };
            form.range.forEach(item => {
                if(item.type == "department") {
                    visible_ids.dept.push(item.id);
                } else if(item.type == 'user') {
                    visible_ids.uids.push(item.id);
                } else if(item.type == 'tag') {
                    visible_ids.tag.push(item.id);
                }
            });
            form.visible_ids = visible_ids;
            form.user_id = getUserWxId();
            resolve(form);
        })
    },
    component: 'NoticeOther'
}
