import {getUserWxId} from "../js/storage";

export default {
    name: '通知公告',
    url: '/admin/fs-announce', // rest接口表名
    query: '', // rest接口query参数
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {title: '时间', dataIndex: 'create_time'},
            {title: '公告内容', dataIndex: 'content'},
        ]
    },
    // 新增/编辑查询表单
    items: [
        {
            key: 'content',
            label: '公告内容',
            component: 'a-textarea',
            props: {
                placeholder: '请输入内容',
                rows: 4
            },
            rules: [{ required: true, message: '请输入内容', trigger: 'blur' }]
        },
    ],
    // 详情接口expand字段
    expand: '',
    init: {}, // 新增实体初始化字段
    // 详情渲染字段
    renderItems: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {
                label: '内容',
                key: 'content',
                textarea: true
            },
        ]
    },
    beforeUpdate: function (form) {
        return new Promise(resolve => {
            if(!form.id) {
                form.user_id = getUserWxId();
            }
            resolve(form);
        })
    }
}
