import {getStaffTypeName, staffTypes} from "../../hr/book";
import req from "../../js/req";

const lessonContentTypeList = [
  // { label: 1, value: "直播" },
  { value: 2, label: "视频" },
  { value: 3, label: "图文" },
  { value: 4, label: "文件" },
]

const coverItem = {
  key: 'cover_image',
  label: '封面图',
  component: 'form-upload',
  props: {
    limit: 1,
  },
  span: 12,
  rules: [{ required: true, message: '请上传封面图', trigger: 'change' }]
}

const handleRecommendChange = function (val, item) {
  const url = `/admin/learn-course/${item.id}`;
  this.$set(item, "loading", true);
  this.$axios({
    url,
    method: "PATCH",
    data: {
      is_recommend: val ? 1 : 2
    }
  }).then(res => this.$set(item, 'is_recommend', res.is_recommend)).finally(() => this.$set(item, "loading", false));
}


const getContentTypeForm = function (type) {
  let res = [];
  switch (type) {
    case 2:
      res = [
        {
          key: 'content',
          label: '视频上传',
          component: 'form-upload',
          props: {
            limit: 1,
            mediaType: "video"
          },
          span: 12,
          rules: [{ required: true, message: '请上传视频', trigger: 'change' }]
        },
      ]
      break;
    case 3:
      res = [
        {
          key: 'content',
          label: '图文内容',
          component: 'rich-editor',
        }
      ]
      break;
    case 4:
      res = [
        {
          key: 'files',
          label: '文件上传',
          component: 'file-upload',
          props: {
            limit: 1,
          },
          span: 12,
          rules: [{ required: true, message: '请上传文件', trigger: 'change' }]
        }
      ]
      break;
  }
  return res;
}

export default {
  name: "课程",
  url: "/admin/learn-course", // rest接口表名
  query: "&sort=-id&expand=learnType", // rest接口query参数
  formItemRowType: "flex",
  labelCol: {flex: '120px'},
  wrapperCol: {flex: 'auto'},
  showRowNum: true,
  // 列表渲染列
  columns: (ctx) => {
    // eslint-disable-next-line no-unused-vars
    const h = ctx.$createElement;
    return [
      { title: "课程名称", dataIndex: "name" },
      { title: "发布时间", dataIndex: "create_time" },
      { title: "课程类型", dataIndex: "learnType", customRender: text => text?.name || "--" },
      { title: "封面图", dataIndex: "cover_image", customRender: text => text ? <s-img src={text} /> : '--' },
      { title: "适用身份", dataIndex: "identity", customRender: text => text ? getStaffTypeName(text) : '全部'},
      {
        title: "学修推荐",
        dataIndex: "is_recommend",
        customRender: (text, record) => {
          return  <a-space>
            <a-switch loading={record.loading} checked={text == 1} onChange={(val) => handleRecommendChange.call(ctx, val, record)}/>
            <span>{text == 1 ? '推荐' : '不推荐'}</span>
          </a-space>
        }
      },
    ];
  },
  searchKeyType: {
    name: 1,
  },
  // 列表查询表单
  queryForm: [
    {
      key: "name",
      label: "",
      component: "a-input",
      props: {
        placeholder: "输入课程名称查询",
        allowClear: true,
      },
    },
    {
      key: "learn_course_type_id",
      label: "",
      component: "a-select",
      props: {
        placeholder: "选择课程类型筛选",
        options: [],
        allowClear: true,
      },
      update: ["change"],
      style: {
        width: "180px"
      }
    },
    {
      key: "is_recommend",
      label: "",
      component: "a-select",
      props: {
        placeholder: "是否学修推荐",
        options: [
          { value: 1, label: "是" },
          { value: 2, label: "否" },
        ],
        allowClear: true,
      },
      update: ["change"],
      style: {
        width: "180px"
      }
    },
  ],
  // 新增/编辑查询表单
  items: [
    {
      key: "name",
      label: "课程名称",
      component: "a-input",
      props: {
        placeholder: "请输入课程名称",
        allowClear: true,
      },
      span: 12,
      rules: [{ required: true, message: "请输入课程名称", trigger: "blur" }],
    },
    {
      key: 'identity',
      label: '适用身份',
      component: 'a-select',
      props: {
        placeholder: '请选择',
        options: [
          { key: 0, title: "全部" },
          ...staffTypes
        ]
      },
      span: 12,
      rules: [{ required: true, message: '适用身份', trigger: 'change' }]
    },
    {
      key: 'learn_course_type_id',
      label: '课程类型',
      component: 'a-select',
      props: {
        placeholder: '请选择课程类型',
        options: []
      },
      listeners: {
        created(val, init) {
          if(init) {
            req("/admin/learn-type?pageSize=1000").then(res => {
              const con = this.controls.find(c => c.key == "learn_course_type_id");
              if(con) {
                const options = res.data.map(item => {
                  return {
                    value: item.id,
                    label: item.name
                  }
                })
                this.$set(con.props, "options", options)
              }
            });
          }
        }
      },
      span: 12,
      rules: [{ required: true, message: '请选择课程类型', trigger: 'change' }]
    },
    {
      key: 'courseware',
      label: '内容形式',
      component: 'a-radio-group',
      props: {
        placeholder: '请选择课程内容形式',
        options: [
            ...lessonContentTypeList
        ]
      },
      span: 12,
      listeners: {
          change(e) {
            const val = e.target.value;
            this.controls = this.controls.filter(c => c.key != "content");
            this.$nextTick(() => {
              const con = getContentTypeForm(val);
              const index = this.controls.findIndex(c => c.key == "courseware");
              if(con && index >= 0) {
                this.controls.splice(index + 1, 0, ...con);
              }
            })
          }
      },
      rules: [{ required: true, message: '请选择课程内容形式', trigger: 'change' }]
    },
    coverItem,
    {
      key: 'recommend',
      label: '学修推荐',
      component: 'a-switch',
      span: 12,
    },
  ],
  init: {}, // 新增实体初始化字段
  expand: "?expand=learnType",
  // 详情渲染字段
  renderItems: (ctx, detail) => {
    // eslint-disable-next-line no-unused-vars
    const h = ctx.$createElement;
    const list = [
      {
        label: "课程名称",
        key: "name",
      },
      {
        label: "适用身份",
        slot: entity => <span>{entity.identity ? getStaffTypeName(entity.identity) : '全部'}</span>
      },
      {
        label: "课程类型",
        slot: entity => <span>{entity.learnType ? entity.learnType.name : '--'}</span>
      },
    ]
    switch (detail.courseware) {
      case 2:
        list.push({
          label: "课程内容",
          key: "video",
          media: true
        })
        break;
      case 3:
        list.push({
          label: "课程内容",
          key: "content",
          rich: true
        })
        break;
      case 4:
        list.push({
          label: "课程内容",
          key: "files",
          file: true
        })
        break;
    }
    list.push({
      label: "学修推荐",
      slot: entity => <span>{entity.is_recommend == 1 ? "是" : "否"}</span>
    })
    return list;
  },
  // 编辑实体前处理函数
  dealDetail: function(detail) {
    return new Promise(resolve => {
      if(detail.courseware == 2) {
        detail.video = [
          {
            type: 'video',
            poster: detail.cover_image,
            url: detail.content
          }
        ]
      } else if(detail.courseware == 4) {
        detail.files = detail.content ? JSON.parse(detail.content) : [];
      }
      resolve();
    })
  },
  beforeUpdate: function (form) {
    return new Promise(resolve => {
      form.is_recommend = form.recommend ? 1 : 2;
      if(form.courseware == 4) {
        form.content = form.files && form.files.length > 0 ? JSON.stringify(form.files) : null;
      }
      resolve(form);
    })
  }
};
