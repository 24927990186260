import {
    getAssRuleType,
    assRuleTypes,
    getSystemAssTypeText,
    getSysRuleText,
    getAssRuleCatText,
    dealAssTemp, assTempBaseForm, subjectiveTempExtraForm, objectiveTempExtraForm
} from "../hr/ass";
import {getStaffTypeName} from "../hr/book";
import {clone} from "../js/tool";

const items = clone(assTempBaseForm);
items[1].listeners = {
    change(val) {
        if(val) {
            // 主观项控件下标
            const si = this.controls.findIndex(c => c.key === "detail");
            // 客观项控件下标
            const oi = this.controls.findIndex(c => c.key === "source");
            if(val === 1) {
                if(oi >= 0) {
                    this.controls.splice(oi, objectiveTempExtraForm.length);
                }
                if(si === -1) {
                    this.controls.push(...subjectiveTempExtraForm);
                    this.setForm();
                }
            } else if(val === 2) {
                if(si >= 0) {
                    this.controls.splice(si, subjectiveTempExtraForm.length);
                }
                if(oi === -1) {
                    this.controls.push(...objectiveTempExtraForm);
                    this.setForm();
                }
            }
        }
    }
};

export default {
    name: '评价指标',
    url: '/admin/evaluate-temp', // rest接口表名
    query: '&sort=order,-id', // rest接口query参数
    formItemRowType: "flex",
    labelCol: {flex: '120px'},
    wrapperCol: {flex: 'auto'},
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {title: '指标名称', dataIndex: 'name'},
            {
                title: '指标类型',
                dataIndex: 'type',
                customRender: text => getAssRuleType(text)
            },
            {
                title: '所属大类',
                dataIndex: 'cat',
                customRender: text => getAssRuleCatText(text)
            },
            {
                title: '适用对象',
                dataIndex: 'group',
                customRender: text => getStaffTypeName(text)
            },
            {
                title: '单项指标权重',
                dataIndex: 'percent',
                customRender: text => `${text || 0}%`
            },
            {
                title: '单项总分',
                dataIndex: 'score',
                customRender: (text, record) => (record.percent || 0) + '分'
            },
        ]
    },
    // 列表查询表单
    queryForm: [
        {
            key: 'name',
            label: '',
            component: 'a-input',
            props: {
                placeholder: '输入指标名称查询',
            },
        },
        {
            key: 'type',
            label: '',
            component: 'a-select',
            props: {
                placeholder: '选择指标类型筛选',
                options: [...assRuleTypes],
                allowClear: true
            },
            style: {
                width: "183px"
            }
        },
    ],
    // 新增/编辑查询表单
    items,
    init: {type: 1}, // 新增实体初始化字段
    // 详情渲染字段
    renderItems: (ctx, detail) => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement;
        const items = [
            {
                label: '指标名称',
                key: 'name'
            },
            {
                label: '指标类型',
                slot: entity => <span>{getAssRuleType(entity.type)}</span>
            },
            {
                label: '适用对象',
                slot: entity => <span>{getStaffTypeName(entity.group)}</span>
            },
            {
                label: '单项指标权重',
                slot: entity => <span>{entity.percent}%</span>
            },
            {
                label: '单项总分',
                slot: entity => <span>{entity.percent}</span>
            },
        ];
        if(detail.type == 1) {
            items.push({
                label: '自评权重',
                slot: entity => <span>{entity.selfNum}%</span>
            }, {
                label: '主管评权重',
                slot: entity => <span>{entity.directorNum}%</span>
            }, {
                label: '指标说明',
                key: 'detail',
                textarea: true
            });
        } else if(detail.type == 2) {
            items.push({
                label: '数据来源',
                key: 'source',
            }, {
                label: '评价项',
                slot: entity => <span>{getSystemAssTypeText(entity.assType)}</span>
            }, {
                label: '指标说明',
                slot: entity => entity.normList && entity.normList.length > 0 ?
                    <div>{entity.normList.map((item, i) => <p>{getSysRuleText(item, entity.assType, i)}</p>)}</div> :
                    <span>无</span>
            });
        }
        return items;
    },
    dealList (list, fn) {
        list.forEach(dealAssTemp);
        fn();
    },
    dealDetail: function(detail) {
        return new Promise(resolve => {
            dealAssTemp(detail);
            resolve();
        })
    },
    beforeUpdate: function (form) {
        return new Promise((resolve, reject) => {
            let config = {};
            let error = "";
            // 主观项
            if(form.type === 1) {
                config = {
                    selfNum: form.selfNum * 1,
                    directorNum: form.directorNum * 1
                }
                // if(config.selfNum + config.directorNum != 100) {
                //     error = "自评权重和主管评权重之和必须等于100";
                // }
            } else if(form.type === 2) {
                config = {
                    source: form.source,
                    assType: form.assType,
                    normList: form.normList
                }
            }
            if(error) {
                this.$message.warning(error);
                reject(error);
            } else {
                form.config = JSON.stringify(config);
                resolve(form);
            }
        })
    }
}
