export default {
  name: "课程类型",
  url: "/admin/learn-type", // rest接口表名
  query: "&sort=-id&expand=courseNum", // rest接口query参数
  listClass: "md",
  showRowNum: true,
  // 列表渲染列
  columns: (ctx) => {
    // eslint-disable-next-line no-unused-vars
    const h = ctx.$createElement;
    return [
      { title: "课程类型", dataIndex: "name" },
      { title: "课程数量", dataIndex: "courseNum" },
    ];
  },
  searchKeyType: {
    name: 1,
  },
  // 列表查询表单
  queryForm: [
    {
      key: "name",
      label: "",
      component: "a-input",
      props: {
        placeholder: "输入课程类型查询",
        allowClear: true,
      },
    },
  ],
  // 新增/编辑查询表单
  items: [
    {
      key: "name",
      label: "课程类型",
      component: "a-input",
      props: {
        placeholder: "请输入课程类型名称",
        allowClear: true,
      },
      rules: [{ required: true, message: "请输入课程类型名称", trigger: "blur" }],
    },
  ],
  init: {}, // 新增实体初始化字段
  expand: "?expand=courseNum",
  // 详情渲染字段
  renderItems: (ctx) => {
    // eslint-disable-next-line no-unused-vars
    const h = ctx.$createElement;
    return [
      {
        label: "课程类型",
        key: "name",
      },
      {
        label: "课程数量",
        key: "courseNum",
      },
    ];
  },
};
